import axios from 'axios'
import MaterialTable from "material-table"
import moment from 'moment'
import { isEqual } from 'lodash'
import React , { useState, useEffect } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { useUIContext } from "../../ContextLib/contextHooks"
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage"

export default function ShadowReport(props) {
    let componentId = "Coverlookup_ShadowReport"
    const ui = useUIContext(componentId)
    const {CoverKey} = props
    const [isLoading, setLoading] = useState(true)
    const [CoverLevelData, setCoverData] = useState([])
    const [locationData, setLocationData] = useState([])
    const [accountData, setAccountData] = useState([])
    const [scheduleData, setScheduleData] = useState([])

    //#region CONSTANT VARIABLES
    const coverColumns = [
      { field: 'coverKey', title: 'Coverkey', width: 100 , editable: 'never', emptyValue: '-'},
      { field: 'login', title: 'Login', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.login === "" ? {isValid: false, helperText: 'Login is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'password', title: 'Password', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.password === "" ? {isValid: false, helperText: 'Password is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'accessKey', title: 'Access Key', width: 125 , editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
      { field: 'created', title: 'Date Created', width: 125 , editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}}, 
      { field: 'orgID', title: 'Org ID', width: 100 , editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
      { field: 'vip', title: 'VIP', width: 100 , emptyValue: '-', editComponent: props => { return <Select native value={props.value} onChange={(event) => {props.onChange(event.target.value)}}><option value={0}>0</option><option value={1}>1</option> </Select>}},
      { field: 'orgName', title: 'Org Name', width: 150 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.orgName} placement="top-start"><span>{dataRow.orgName}</span></HtmlTooltip>}, validate: (rowData) => rowData.orgName === "" ? {isValid: false, helperText: 'Org name is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'status', title: 'Status', width: 100 , emptyValue: '-', editComponent: props => { return <Select native value={props.value} onChange={(event) => {props.onChange(event.target.value)}}><option disabled value={-10}>-10</option><option disabled value={-1}>-1</option><option value={0}>0</option><option value={1}>1</option><option disabled value={10}>10</option> </Select>}},
      { field: 'sponsorB', title: 'SponsorB', width: 100 , type: 'numeric', emptyValue: '-', align:'left', cellStyle: { textAlign: 'left'}},
      { field: 'sponsorF', title: 'SponsorF', width: 100 , type: 'numeric', emptyValue: '-', align:'left', cellStyle: { textAlign: 'left'}},
      { field: 'features', title: 'Features', width: 100 , type: 'numeric', emptyValue: '-', align:'left', cellStyle: { textAlign: 'left'}},
      { field: 'faxSchedTo', title: 'Fax To', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.faxSchedTo === "" ? {isValid: false, helperText: 'Fax To is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'emailSchedTo', title: 'Email To', width: 150 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.emailSchedTo} placement="top-start"><span>{dataRow.emailSchedTo}</span></HtmlTooltip>}, validate: (rowData) => rowData.emailSchedTo === "" ? {isValid: false, helperText: 'Email To is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'email', title: 'Email Address', width: 200 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}, validate: (rowData) => rowData.email === "" ? {isValid: false, helperText: 'Email is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
    ]

    const locationColumns = [
      { field: 'locKey', title: 'LocKey', width: 100 , editable: 'never', emptyValue: '-'},
      { field: 'login', title: 'Login', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.login === "" ? {isValid: false, helperText: 'Login is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'password', title: 'Password', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.password === "" ? {isValid: false, helperText: 'Password is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'accessKey', title: 'Access Key', width: 125 , editable: 'never', emptyValue: '-'},
      { field: 'created', title: 'Date Created', width: 125 , editable: 'never', emptyValue: '-'},
      { field: 'npi', title: 'NPI', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.npi === "" ? {isValid: false, helperText: 'NPI is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 20}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'orgName', title: 'Org Name', width: 150 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.orgName} placement="top-start"><span>{dataRow.orgName}</span></HtmlTooltip>}, validate: (rowData) => rowData.orgName === "" ? {isValid: false, helperText: 'Org name is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'status', title: 'Status', width: 100 , emptyValue: '-', editComponent: props => { return <Select native value={props.value} onChange={(event) => {props.onChange(event.target.value)}}><option disabled value={-1}>-1</option><option disabled value={0}>0</option><option value={-10}>-10</option><option value={10}>10</option><option disabled value={1}>1</option> </Select>}},
      { field: 'address1', title: 'Address 1', width: 150 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.address1} placement="top-start"><span>{dataRow.address1}</span></HtmlTooltip>}, validate: (rowData) => rowData.address1 === "" ? {isValid: false, helperText: 'Address 1 is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'address2', title: 'Address 2', width: 150 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.address2} placement="top-start"><span>{dataRow.address2}</span></HtmlTooltip>}, validate: (rowData) => rowData.address2 === "" ? {isValid: false, helperText: 'Address 2 is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'city', title: 'City', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.city === "" ? {isValid: false, helperText: 'City is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 50}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'state', title: 'State', width: 100 , emptyValue: '-', editComponent: props => { return <Autocomplete disableClearable value={props.value} options={stateOptions.map(x => x.value)} onChange={(event, newValue) => {props.onChange(newValue)}} renderInput={(params) => <TextField {...params} value={props.value} />}/>}},
      { field: 'zip', title: 'ZIP Code', width: 130 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.zip === "" ? {isValid: false, helperText: 'Zip Code is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 12}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'frontLine', title: 'Front Line', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.frontLine === "" ? {isValid: false, helperText: 'Frontline is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'email', title: 'Email Address', width: 200 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}, validate: (rowData) => rowData.email === "" ? {isValid: false, helperText: 'Email is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
    ]

    const accountColumns = [
      { field: 'acctKey', title: 'AcctKey', width: 100 , editable: 'never', emptyValue: '-'},
      { field: 'login', title: 'Login', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.login === "" ? {isValid: false, helperText: 'Login is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'password', title: 'Password', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.password === "" ? {isValid: false, helperText: 'Password is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 25}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'accessKey', title: 'Access Key', width: 125 , editable: 'never', emptyValue: '-'},
      { field: 'created', title: 'Date Created', width: 125 , editable: 'never', emptyValue: '-'},
      { field: 'npi', title: 'NPI', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.npi === "" ? {isValid: false, helperText: 'NPI is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 20}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'title', title: 'Title', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.title === "" ? {isValid: false, helperText: 'Title is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 10}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'firstName', title: 'First Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.firstName === "" ? {isValid: false, helperText: 'First name is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 50}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'mi', title: 'MI', width: 100 , emptyValue: '-', editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 2}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'lastName', title: 'Last Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.lastName === "" ? {isValid: false, helperText: 'Last name is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 50}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'fax', title: 'Fax', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.fax === "" ? {isValid: false, helperText: 'Fax is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'status', title: 'Status', width: 100 , emptyValue: '-', editComponent: props => { return <Select native value={props.value} onChange={(event) => {props.onChange(event.target.value)}}><option disabled value={-1}>-1</option><option disabled value={0}>0</option><option value={-10}>-10</option><option value={10}>10</option><option disabled value={1}>1</option> </Select>}},
      { field: 'acctType', title: 'Acct Type', width: 100 , type:'numeric', emptyValue: '-'},
      { field: 'speciality', title: 'Specialty', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.speciality === "" ? {isValid: false, helperText: 'Specialty is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 60}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'frontLine', title: 'Front Line', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.frontLine === "" ? {isValid: false, helperText: 'Frontline is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'email', title: 'Email Address', width: 200 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}, validate: (rowData) => rowData.email === "" ? {isValid: false, helperText: 'Email is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
    ]

    const scheduleColumns = [
      { field: 'locKey', title: 'LocKey', width: 100 , editable: 'never', emptyValue: '-'},
      { field: 'acctKey', title: 'AcctKey', width: 100 , editable: 'never', emptyValue: '-'},
      { field: 'pmsType', title: 'PMSType', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.pmsType === "" ? {isValid: false, helperText: 'PMS Type is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 50}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'emailSchedTo', title: 'Email Sched To', width: 200 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.emailSchedTo} placement="top-start"><span>{dataRow.emailSchedTo}</span></HtmlTooltip>}, validate: (rowData) => rowData.emailSchedTo === "" ? {isValid: false, helperText: 'Email Sched To is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'faxSchedTo', title: 'Fax Sched To', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.faxSchedTo === "" ? {isValid: false, helperText: 'Fax Sched To is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'frontLine', title: 'Front Line', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.frontLine === "" ? {isValid: false, helperText: 'Frontline is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 11}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'frontLineExt', title: 'Front Line Ext', width: 125 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}, validate: (rowData) => rowData.frontLineExt === "" ? {isValid: false, helperText: 'Frontline Ext is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 10}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
      { field: 'status', title: 'Status', width: 100 , editable: 'never' , emptyValue: '-'},
      { field: 'englishURL', title: 'English URL', width: 200 , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.englishURL} placement="top-start"><span>{dataRow.englishURL}</span></HtmlTooltip>}, validate: (rowData) => rowData.englishURL === "" ? {isValid: false, helperText: 'English URL is required'} : true, editComponent: props => { return <TextField value={props.value} inputProps={{maxLength: 100}} onChange={(event) => {props.onChange(event.target.value)}} error={props.error} helperText={props.helperText}/>}},
    ]

    const stateOptions = [
      {key: "AL", value: "AL"}, {key: "AK", value: "AK"}, {key: "AZ", value: "AZ"}, {key: "AR", value: "AR"}, {key: "CA", value: "CA"}, {key: "CO", value: "CO"}, {key: "CT", value: "CT"}, {key: "DE", value: "DE"},
      {key: "FL", value: "FL"}, {key: "GA", value: "GA"}, {key: "HI", value: "HI"}, {key: "ID", value: "ID"}, {key: "IL", value: "IL"}, {key: "IN", value: "IN"}, {key: "IA", value: "IA"}, {key: "KS", value: "KS"}, 
      {key: "KY", value: "KY"}, {key: "LA", value: "LA"}, {key: "ME", value: "ME"}, {key: "MD", value: "MD"}, {key: "MA", value: "MA"}, {key: "MI", value: "MI"}, {key: "MN", value: "MN"}, {key: "MS", value: "MS"}, 
      {key: "MO", value: "MO"}, {key: "MT", value: "MT"}, {key: "NE", value: "NE"}, {key: "NV", value: "NV"}, {key: "NH", value: "NH"}, {key: "NJ", value: "NJ"}, {key: "NM", value: "NM"}, {key: "NY", value: "NY"}, 
      {key: "NC", value: "NC"}, {key: "ND", value: "ND"}, {key: "OH", value: "OH"}, {key: "OK", value: "OK"}, {key: "OR", value: "OR"}, {key: "PA", value: "PA"}, {key: "RI", value: "RI"}, {key: "SC", value: "SC"}, 
      {key: "SD", value: "SD"}, {key: "TN", value: "TN"}, {key: "TX", value: "TX"}, {key: "UT", value: "UT"}, {key: "VT", value: "VT"}, {key: "VA", value: "VA"}, {key: "WA", value: "WA"}, {key: "WV", value: "WV"}, 
      {key: "WI", value: "WI"}, {key: "WY", value: "WY"}   
    ]

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 250,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
          fontFamily:'Noto Sans, sans-serif',
        },
      }))(Tooltip)
    //#endregion

    //#region METHODS
    const handleCoverUpdate = async(newData, oldData) => {
      ui.ShowOverlay()
        try {
          const apiKey = `${process.env.REACT_APP_API_KEY}`
          var model = {
            coverKey: oldData.coverKey,
            login: newData.login,
            password: newData.password,
            orgID: newData.orgID,
            vip: Number(newData.vip),
            orgName: newData.orgName,
            status: Number(newData.status),
            sponsorB: newData.sponsorB,
            sponsorF: newData.sponsorF,
            features: newData.features,
            emailSchedTo: newData.emailSchedTo,
            faxSchedTo: newData.faxSchedTo,
            email: newData.email
          }
          
          const url = `${process.env.REACT_APP_COVERLOOKUP_API}/updateCoverLevelData?secretCode=${apiKey}`
          const res = await axios.post(url, model)
          
          if (res.status === 200) { 
            getCoverLevelData()
            ui.ShowSnackbar("Cover level data updated successfully.", Severity.success)
          }
  
        } catch (error) {
          ui.ShowSnackbar("Unexpected error in updating cover level shadow report data.", Severity.error)
        }
      ui.HideOverlay()
    }

    const handleLocationUpdate = async(newData, oldData) => {
      ui.ShowOverlay()
        try {
          const apiKey = `${process.env.REACT_APP_API_KEY}`
          var model = {
            coverKey: CoverKey,
            locKey: oldData.locKey,
            login: newData.login,
            password: newData.password,
            npi: newData.npi,
            orgName: newData.orgName,
            status: Number(newData.status),
            address1: newData.address1,
            address2: newData.address2,
            city: newData.city,
            state: newData.state,
            zip: newData.zip,
            frontLine: newData.frontLine,
            email: newData.email
          }
          
          const url = `${process.env.REACT_APP_COVERLOOKUP_API}/updateLocationLogin?secretCode=${apiKey}`
          const res = await axios.post(url, model)
          
          if (res.status === 200) { 
            getLocationLoginData()
            ui.ShowSnackbar("Location login updated successfully.", Severity.success)
          }
  
        } catch (error) {
          ui.ShowSnackbar("Unexpected error in updating location login shadow report data.", Severity.error)
        }
      ui.HideOverlay()
    }

    const handleAccountUpdate = async(newData, oldData) => {
      ui.ShowOverlay()
        try {
          const apiKey = `${process.env.REACT_APP_API_KEY}`
          var model = {
            coverKey: CoverKey,
            acctKey: oldData.acctKey,
            login: newData.login,
            password: newData.password,
            npi: newData.npi,
            title: newData.title,
            firstName: newData.firstName,
            mi: newData.mi,
            lastName: newData.lastName,
            fax: newData.fax,
            status: Number(newData.status),
            acctType: newData.acctType,
            speciality: newData.speciality,
            frontLine: newData.frontLine,
            email: newData.email
          }
          
          const url = `${process.env.REACT_APP_COVERLOOKUP_API}/updateAccountLogin?secretCode=${apiKey}`
          const res = await axios.post(url, model)
          
          if (res.status === 200) { 
            getAccountLoginData()
            ui.ShowSnackbar("Account login updated successfully.", Severity.success)
          }
  
        } catch (error) {
          ui.ShowSnackbar("Unexpected error in updating account login shadow report data.", Severity.error)
        }
      ui.HideOverlay()
    }

    const handleScheduleUpdate = async(newData, oldData) => {
      ui.ShowOverlay()
        try {
          const apiKey = `${process.env.REACT_APP_API_KEY}`
          var model = {
            coverKey: CoverKey,
            acctKey: oldData.acctKey,
            locKey: oldData.locKey,
            pmsType: newData.pmsType,
            emailSchedTo: newData.emailSchedTo,
            faxSchedTo: newData.faxSchedTo,
            frontLine: newData.frontLine,
            frontLineExt: newData.frontLineExt,
            englishURL: newData.englishURL
          }
          
          const url = `${process.env.REACT_APP_COVERLOOKUP_API}/updateSchedule?secretCode=${apiKey}`
          const res = await axios.post(url, model)
          
          if (res.status === 200) { 
            getScheduleData()
            ui.ShowSnackbar("Schedule updated successfully.", Severity.success)
          }
  
        } catch (error) {
          ui.ShowSnackbar("Unexpected error in updating schedule shadow report data.", Severity.error)
        }
      ui.HideOverlay()
    }

    const getCoverLevelData = async () => {
      try {
          var request = {
              params: {
                secretCode: `${process.env.REACT_APP_API_KEY}`,
                coverKey: CoverKey
              }
            }

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getCoverLevelData_Detailed`
            const res = await axios.get(url, request)
            let d = []
            d.push(res.data)
            if (res?.data) { 
              d.forEach((data) => {
                if (data.created) data.created = moment(data.created).format('MM/DD/YYYY - hh:mm A')
              })
              setCoverData(d) 
            }

      } catch (error) {
        ui.ShowSnackbar("Unexpected error in retrieving cover level shadow report data.", Severity.error)
      }
    }  

    const getLocationLoginData = async () => {
      try {
          var request = {
              params: {
                secretCode: `${process.env.REACT_APP_API_KEY}`,
                coverKey: CoverKey
              }
            }

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getLocationsLoginByCover`
            const res = await axios.get(url, request)
            if (res?.data) { 
                res.data.forEach((data) => {
                  if (data.created) data.created = moment(data.created).format('MM/DD/YYYY - hh:mm A')
                })
                setLocationData(res.data) 
            }
            
      } catch (error) {
        ui.ShowSnackbar("Unexpected error in retrieving locations login shadow report data.", Severity.error)
      }
    }  

    const getAccountLoginData = async () => {
      try {
          var request = {
              params: {
                secretCode: `${process.env.REACT_APP_API_KEY}`,
                coverKey: CoverKey
              }
            }

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getAccountsLoginByCover`
            const res = await axios.get(url, request)
            if (res?.data) { 
                res.data.forEach((data) => {
                  if (data.created) data.created = moment(data.created).format('MM/DD/YYYY - hh:mm A')
                })
                setAccountData(res.data) 
            }
            
      } catch (error) {
        ui.ShowSnackbar("Unexpected error in retrieving accounts login shadow report data.", Severity.error)
      }
    }

    const getScheduleData = async () => {
      try {
          var request = {
              params: {
                secretCode: `${process.env.REACT_APP_API_KEY}`,
                coverKey: CoverKey
              }
            }

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getScheduleByCover`
            const res = await axios.get(url, request)
            if (res?.data) { 
                setScheduleData(res.data) 
                setLoading(false)
            }
            
      } catch (error) {
        ui.ShowSnackbar("Unexpected error in retrieving schedule shadow report data.", Severity.error)
      }
    }

    useEffect(() => {        
      getCoverLevelData()
      getLocationLoginData()
      getAccountLoginData()
      getScheduleData()
    }, [])
    //#endregion

    if (isLoading) {
      return <div className="shadowreport">Loading...</div>
    }

    return(
    <div className="shadowreport">

      <Box p={2} bgcolor="background.paper">
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
            <Grid item>
                <Typography variant="h5" color="primary">  
                Shadow Report
                </Typography>
            </Grid>
        </Grid>
      </Box>

      <Divider/>

      <Box p={2}>
        <Typography variant="h6">  
            The following information is Cover level data:
        </Typography>
      </Box>

      <Divider/>
      
      <Box p={4} bgcolor="background.paper" >
          <div className="shadowreport-data">
          
            <MaterialTable
            height="200"
            columns={coverColumns}
            data={CoverLevelData}
            options={{
                pageSize: 1,
                toolbar: false,
                paging: false,
                draggable: false,
                sorting: false,
                tableLayout: 'fixed',
            }}       
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        handleCoverUpdate(newData, oldData);
                        resolve();
                        })
                    }}  
            />

            <Divider className="divider-thick"/>

              <MaterialTable
              height="200"
              title="List of Location logins for Cover:"
              columns={locationColumns}
              data={locationData}
              options={{
                  pageSize: 5,
                  paging: false,
                  draggable: false,
                  sorting: false,
                  tableLayout: 'fixed',
              }}       
              editable={{
                  onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                          handleLocationUpdate(newData, oldData);
                          resolve();
                          })
                      }}  
              />

            <Divider className="divider-thick"/>
               
              <MaterialTable
              height="200"
              title="List of Account logins for Cover:"
              columns={accountColumns}
              data={accountData}
              options={{
                  pageSize: 5,
                  paging: false,
                  draggable: false,
                  sorting: false,
                  tableLayout: 'fixed',
              }}       
              editable={{
                  onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                          handleAccountUpdate(newData, oldData);
                          resolve();
                          })
                      }}  
              />

            <Divider className="divider-thick"/>

              <MaterialTable
                height="200"
                title="List of Schedules for Cover:"
                columns={scheduleColumns}
                data={scheduleData}
                options={{
                    pageSize: 5,
                    paging: false,
                    draggable: false,
                    sorting: false,
                    tableLayout: 'fixed',
                }}       
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            handleScheduleUpdate(newData, oldData);
                            resolve();
                            })
                        }}  
                />
          </div>
      </Box>

    </div >
    )
}
